.react-tel-input{
    font-size: 16px;
    border: none !important;
    border-bottom: 1px solid #31313157 !important;
    border-radius: 0px !important;
    width: 100% !important;
    padding-bottom: 0px !important;
    padding-top: 14px !important;
}

.selected-flag{
    top: 25% !important;
}

